<template>
  <div class="plan_card" :class="{ premium_class: isSponsoredAdvertisement }">
    <div class="plan_top">
      <div class="plan_name">
        <span>{{ name1 }}</span>
        <span class="plan_name2">{{ name2 }}</span>

        <span class="plan_logo">
          <img
            :src="require(`@/assets/images/` + image)"
            alt=""
            v-if="image !== null"
          />
        </span>
      </div>
      <div class="plan_name3">
        <span>{{ name3 }}</span>
      </div>

      <div class="plan_chances">
        <span class="chance" v-for="o in words" :key="o.id">
          <img :src="require(`@/assets/images/tick-icon.svg`)" alt="" />
          <p>{{ o.name }}</p>
        </span>
      </div>
    </div>
    <div class="plan_bottom">
      <router-link to="/carrier-register" class="button but_bordered"
        >Request Pricing</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
  props: {
    id: null,
    image: null,
    name1: null,
    name2: null,
    name3: null,
    words: [],
  },
};
</script>

<style></style>
