<template>
  <home-top-component />
  <home-what-offers-component />
  <hiring-now-component />
  <blue-back-component />
  <about-us-component />
  <partners-logo-component />
</template>

<script>
import HiringNowComponent from '@/components/homeComponents/hiringNowComponent.vue';
import HomeTopComponent from '@/components/homeComponents/homeTopComponent.vue';
import HomeWhatOffersComponent from '@/components/homeComponents/homeWhatOffers.vue';
import BlueBackComponent from '@/components/homeComponents/blueBackComponent.vue'
import AboutUsComponent from '@/components/homeComponents/aboutUsComponent.vue'
import PartnersLogoComponent from '@/components/homeComponents/partnersLogoComponent.vue'

/* eslint-disable */
export default {
  name: 'HomeView',
  components: {
    HomeTopComponent,
    HomeWhatOffersComponent,
    HiringNowComponent,
    BlueBackComponent,
    AboutUsComponent,
    PartnersLogoComponent
  }
}
</script>