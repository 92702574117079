<template>
  <title-block-component />
  <DriverApplication :vacancyId="this.vacancyId"></DriverApplication>
</template>

<script>
import TitleBlockComponent from '@/components/driverApplicationComponents/titleBlockComponent.vue';
import DriverApplicationMainComponent from '@/components/driverApplicationComponents/driverApplicationMainComponent.vue';

export default {
  components: {
    TitleBlockComponent,
    DriverApplication: DriverApplicationMainComponent
  },
  props: ["vacancyId"]
}
</script>

<style lang="scss" scoped>

</style>
