<template>
  <section class="driver_app_main">
    <div class="container">
      <div class="row">
        <div class="form_block">
          <div class="row_form">

            <!-- name -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.carrierData.name.$error }">
                Full name
                <span>*</span>
              </label>

              <input type="text" class="form-control" placeholder="Full name" v-model="carrierData.name"
                @blur="v$.carrierData.name.$touch" :class="{ error_border: v$.carrierData.name.$error }"
                maxlength="30">
              <div class="error_class" v-if="v$.carrierData.name.$error"> Full name field has an error </div>
            </div>

            <!-- company name -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.carrierData.companyName.$error }">
                Company name
                <span>*</span>
              </label>
              <input type="text" class="form-control" placeholder="Your company name" v-model="carrierData.companyName"
                @blur="v$.carrierData.companyName.$touch" :class="{ error_border: v$.carrierData.companyName.$error }"
                maxlength="30">
              <div class="error_class" v-if="v$.carrierData.companyName.$error"> Company name field has an error </div>
            </div>
          </div>
          <div class="row_form">

            <!-- email -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.carrierData.email.$error }">Email
                <span>*</span></label>
              <input type="text" class="form-control" placeholder="Company email" v-model="carrierData.email"
                @blur="v$.carrierData.email.$touch" :class="{ error_border: v$.carrierData.email.$error }">
              <div class="error_class" v-if="v$.carrierData.email.$error"> Email field has an error </div>
            </div>

            <!-- phonenumber -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.carrierData.phoneNumber.$error }">Phone number
                <span>*</span>
              </label>
              <input type="text" class="form-control" placeholder="+1 (206) xxx-xxxx" v-model="carrierData.phoneNumber"
                @blur="v$.carrierData.phoneNumber.$touch" v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
                :class="{ error_border: v$.carrierData.phoneNumber.$error }">

              <div class="error_class" v-if="v$.carrierData.phoneNumber.$error"> Phone number field has an error
              </div>

            </div>
          </div>
          <div class="row_textarea">
            <label class="form-label">Subject</label>
            <textarea class="form-control" name="subject" cols="30" rows="10"
              placeholder="Anything you would like to let us know?" v-model="carrierData.subject" maxlength="500" required></textarea>
          </div>
          <div class="bot_row">
            <div class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" id="check_1" v-model="isConsentButtonChecked">
              <label for="check_1">
                By submitting this form, I expressly consent to be contacted
              </label>
            </div>
            <button :disabled="!isConsentButtonChecked || this.v$.$invalid" @click="contactUs" class="button wm100"
              :class="{ 'but_green': isConsentButtonChecked && !this.v$.$invalid }">
              Continue
            </button>

          </div>

        </div>
        <div class="driver_app_info carier_adv">
          <h4>
            Offers and advantages
          </h4>
          <div class="adv_items">
            <div class="adv_item">
              <img src="@/assets/images/hiring.svg" alt="Hiring All in One">
              <p class="title">Hiring All in One</p>
              <p>
                Our innovative platform that is designed for companies allows making the recruiting process - faster, more efficient, 
                and less time-consuming. Imagine if you could input your hiring criteria, create featured ads and get access to daily 
                oncoming direct leads online. That’s your reality with findCDL.
              </p>
            </div>
            <div class="adv_item">
              <img src="@/assets/images/onlineSevise.svg" alt="Online Service">
              <p class="title">Online Service</p>
              <p>
                Driver candidates can submit a simple application with their basic info and experience and let the trucking companies connect with them.   
                Partner carriers will have access to all oncoming leads through their online account and make the hiring procedure flow more smoothly 
                without stress for the company's HR team.
              </p>
            </div>
            <div class="adv_item">
              <img src="@/assets/images/connection.svg" alt="Connection">
              <p class="title">Connection</p>
              <p>
                This platform acts as the bridge between drivers who are looking for a career in the trucking industry and employers who are seeking qualified candidates. 
                Our marketing strategy emphasizes solely on drivers who are actively searching for their prospective employers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading :active="isLoading" :can-cancel="true" />
    <div class="overlay hidden"></div>

    <!-- Modal notification -->
    <div class="modals modal_notification" id="modal">
      <div class="content_group">
        <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
        <div class="modal_content">
          <span class="not_title">{{ modal.title }}</span>
          <span class="modal_text">{{ modal.body }}</span>
        </div>
      </div>
      <a href="#" class="close btn-close" data-close="modals">⨉</a>
    </div>

  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import CarrierService from "@/services/carrierService";
import { validateEmail } from '@/services/validationService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Loading
},
  methods: {
    contactUs: async function () {
      var self = this;
      this.v$.$validate();
      if (!this.v$.$error && self.isConsentButtonChecked) {
        this.isLoading = true;
        var response = await CarrierService.contactUs(self.carrierData);
        this.isLoading = false;

        if (response.data.successful) {
          self.toggleModal(self.modal.successIcon, "Thank you", "We will contact you soon!");
          setTimeout(function () {
            self.$router.push({
              name: "Home"
            })
          }, 3000);
        }
        else {
          self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
        }
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
          toast_modal.classList.remove('open');
          overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      isLoading: false,
      carrierData: {
        name: "",
        companyName: "",
        subject: "",
        email: "",
        phoneNumber: ""
      },
      isConsentButtonChecked: false,
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    };
  },
  validations() {
    return {
      carrierData: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30)
        },
        companyName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30)
        },
        email: {
          required,
          validateEmail
        },
        phoneNumber: {
          required,
          minLength: minLength(17),
          maxLength: maxLength(19),


        }
      }
    };
  }
};
</script>

<style>

</style>