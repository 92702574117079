<template>
  <section class="hiring_now">
    <div class="container">
      <div class="top_block">
        <div class="col_left">
          <p class="green_txt">
            Featured
          </p>
          <h2>
            Hiring now
          </h2>
        </div>
        <div class="col_right">
          <router-link class="green_txt arrow_right" to="/hiring-now">Show all <img
              src="@/assets/images/arrow-right.svg" alt="arrow"></router-link>
        </div>
      </div>
      <div class="hiring_now_items">
        <Vacancy v-for="vacancy in featuredVacancies" :key=vacancy.id :vacancy="vacancy" />
      </div>
    </div>

    <div>
      <loading :active="isLoading" :can-cancel="true" />
    </div>

  </section>
</template>

<script>

import VacancyService from '@/services/vacancyService';
import VacancyComponent from './inComponent/vacancyComponent.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  mounted() {
    this.loadVacancy();
  },
  components: {
    Vacancy: VacancyComponent,
    Loading
  },
  methods: {
    loadVacancy: async function () {
      var self = this;
      this.isLoading = true;
      var response = await VacancyService.get(self.searchModel);
      this.isLoading = false;
      this.vacancies = response.data.items;
    }
  },
  computed: {
    featuredVacancies() {
      return this.vacancies.filter(x => x.isFutured);
    }
  },
  data() {
    return {
      isLoading: false,
      vacancies: [],
      searchModel: {
        companyName: '',
        stateName: '',
        experienceId: 0,
        driverTypeIdList: [],
        freightTypeIdList: [],
        routeTypeIdList: [],
        endorsementIdList: [],
        timeRange: 'AllTime',
        page: 1,
        size: 6
      }
    }
  },
}
</script>
