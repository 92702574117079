<template>
  <section class="driver_app_main">
    <div class="container">
      <div class="row">
        <div class="form_block form_chek_radio">
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.vacancyModel.subject.$error }">
              Vacancy subject
              <span>*</span>
            </label>
            <input type="text" class="form-control" placeholder="Subject" v-model="vacancyModel.subject"
              @blur="v$.vacancyModel.subject.$touch" :class="{ error_border: v$.vacancyModel.subject.$error }">
            <div class="error_class" v-if="v$.vacancyModel.subject.$error">{{ v$.vacancyModel.subject.$errors[0].$message }}
            </div>
          </div>

          <hr>

          <div class="col_form">
            <label class="form-label">
              States
              <span>*</span>
            </label>

            <Multiselect :classes="{
                            container: 'multiselect_container',
                            options: 'multiselect-options multiselect_options',
                            search: 'multiselect_search',
                            multipleLabel: 'multiple_label'
                         }"
                         :options="states"
                         label="name"
                         valueProp="id"
                         v-model="vacancyModel.stateId"
                         :searchable="true"
                         :caret="true"
                         :hide-selected="false"
                         :close-on-select="false"
                         :can-deselect="false"
                         noOptionsText="No states found!" 
                         noResultsText="No states found!"
                         placeholder="States"
            >
            </Multiselect>

          </div>

          <div class="row_form bot_divider">
            <!-- DRIVER TYPES -->
            <div class="col_form">
              <div class="col_title">
                Driver type <span>*</span>
              </div>

              <div>
                <div v-for="driverType in driverTypes" :key="driverType.id" class="form-check">
                  <input class="form-styled checkbox-styled" type="checkbox" :value="driverType.id"
                    v-model="vacancyModel.driverTypeIds" :id="`driver_${driverType.id}`" />
                  <label :for="`driver_${driverType.id}`">
                    {{ driverType.type }}
                  </label>
                </div>
              </div>
            </div>





            <!-- EXPERIENCES -->
            <div class="col_form">
              <div class="col_title">
                Experience   <span>*</span>
              </div>

              <div>
                <div v-for="experience in experiences" :key="experience.id" class="form-check">
                  <input type="radio" :value="experience.id" v-model="vacancyModel.experienceId"
                    class="form-styled radio_styled" :id="`experience_${experience.id}`">
                  <label :for="`experience_${experience.id}`">
                    {{ experience.value }}
                  </label>
                </div>
              </div>
            </div>





            <!-- ROUTE TYPES -->
            <div class="col_form">
              <div class="col_title">
                Route type <span>*</span>
                <p>Check all that apply</p>
              </div>

              <div>
                <div v-for="routeType in routeTypes" :key="routeType.id" class="form-check">
                  <input class="form-styled checkbox-styled" type="checkbox" :value="routeType.id"
                    v-model="vacancyModel.routeTypeIds" :id="`routeType_${routeType.id}`" />
                  <label :for="`routeType_${routeType.id}`">
                    {{ routeType.type }}
                  </label>
                </div>
              </div>
            </div>
          </div>





          <!-- FREIGHT TYPES -->
          <div class="row_form">
            <div class="col_form">
              <div class="col_title">
                Freight type <span>*</span>
                <p>Check all that apply</p>
              </div>

              <div>
                <div v-for="freightType in freightTypes" :key="freightType.id" class="form-check">
                  <input class="form-styled checkbox-styled" type="checkbox" :value="freightType.id"
                    v-model="vacancyModel.freightTypeIds" :id="`freightType_${freightType.id}`" />
                  <label :for="`freightType_${freightType.id}`">
                    {{ freightType.type }}
                  </label>
                </div>
              </div>
            </div>
            <!-- ENDORSEMENTS -->
            <div class="col_form">
              <div class="col_title">
                Endorsements <span>*</span>
              </div>
              <div>
                <div v-for="endorsement in endorsements" :key="endorsement.id" class="form-check">
                  <input class="form-styled checkbox-styled" type="checkbox" :value="endorsement.id"
                    v-model="vacancyModel.endorsementIds" :id="`endorsement_${endorsement.id}`" />
                  <label :for="`endorsement_${endorsement.id}`">
                    {{ endorsement.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- TO DO - SOME INPUTS TO WRITE VACANCY OFFERS -->

          <div class="bot_row">
            <strong>
              You'll get leads
            </strong>
            <button @click="createVacancy" class="button" :class="{'but_blue': !this.v$.$invalid}" :disabled="this.v$.$invalid">Create</button>
          </div>
        </div>

      </div>

      <div>
        <loading :active="isLoading" :can-cancel="true" />
      </div>

    </div>
  </section>
</template>

<script>
import DriverTypeService from '@/services/driverTypeService.js';
import FreightTypeService from '@/services/freightTypeService.js';
import RouteTypeService from '@/services/routeTypeService.js';
import EndorsementService from '@/services/endorsementService.js';
import ExperienceService from '@/services/experienceService.js';
import VacancyService from '@/services/vacancyService.js';
import StateService from '@/services/stateService.js';

// SPINNER
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

// VALIDATION
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";

// MULTISELECT
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";

export default {
  setup: () => ({
    v$: useVuelidate()
  }),
  mounted() {
    this.loadDriverTypes();
    this.loadFreightTypes();
    this.loadRouteTypes();
    this.loadEndorsements();
    this.loadExperiences();
    this.loadStates();
  },
  components: {
    Loading,
    Multiselect
  },
  methods: {
    loadDriverTypes: async function () {
      this.isLoading = true;

      var response = await DriverTypeService.getDriverTypes();
      this.driverTypes = response.data.items;

      this.isLoading = false;
    },
    loadFreightTypes: async function () {
      this.isLoading = true;
      var response = await FreightTypeService.getFreightTypes();
      this.freightTypes = response.data.items;
      this.isLoading = false;
    },
    loadRouteTypes: async function () {
      this.isLoading = true;
      var response = await RouteTypeService.getRouteTypes();
      this.routeTypes = response.data.items;
      this.isLoading = false;
    },
    loadEndorsements: async function () {
      this.isLoading = true;
      var response = await EndorsementService.getEndorsements();
      this.endorsements = response.data.items;
      this.isLoading = false;
    },
    loadExperiences: async function () {
      this.isLoading = true;
      var response = await ExperienceService.getExperiences();
      this.experiences = response.data.items;
      this.isLoading = false;
    },
    loadStates: async function () {
      this.isLoading = true;
      var response = await StateService.getStates();
      this.isLoading = false;
      this.states = response.data.items;
    },
    createVacancy: async function () {
      if (!this.$store.getters.carrierAccessToken) {
        this.$moshaToast({
          title: `You're not logged in!`,
          description: 'Please login'
        },
          {
            type: 'danger',
            position: 'top-center',
            transition: 'zoom',
            timeout: '3000',
            hideProgressBar: 'false',
            toastBackgroundColor: '#cc3300',
            showIcon: 'true',
            showCloseButton:'false'
          }
        );
        return;
      }

      this.isLoading = true;
      var self = this;
      var response = await VacancyService.createVacancy(self.vacancyModel);
      this.isLoading = false;

      if (response.data.successful) {
        this.$moshaToast({
          title: `Vacancy successfully created!`,
          description: 'You have successfully created a vacancy'
        },
          {
            type: 'success',
            position: 'top-center',
            transition: 'zoom',
            timeout: '3000',
            hideProgressBar: 'false',
            toastBackgroundColor: '#339900',
            showIcon: 'true',
            showCloseButton:'false'
          }
        );
        
        
      }
      else {
        this.$moshaToast({
          title: `Could not create a vacancy!`,
          description: 'Please login or do the steps correctly.'
        },
          {
            type: 'danger',
            position: 'top-center',
            transition: 'zoom',
            timeout: '3000',
            hideProgressBar: 'false',
            toastBackgroundColor: '#cc3300',
            showIcon: 'true',
            showCloseButton:'false'
          }
        );
       
      }
    }
  },
  data() {
    return {
      isLoading: false,
      vacancyModel: {
        experienceId: null,
        driverTypeIds: [],
        freightTypeIds: [],
        routeTypeIds: [],
        endorsementIds: [],
        subject: null,
        stateId: null,
        // TO DO - LATER MAKE DYNAMIC
        offers: [
          {
            value: 'After 1st yaer average salary &78k/year - Dedicated Routes!'
          },
          {
            value: 'Sign-On Bonuns *will vary by location'
          },
          {
            value: 'Medical, dental and vision insurance, 401k and Stock Purchase Plan'
          },
          {
            value: 'Many routes under 24 hours out'
          },
          {
            value: 'Highly maintained equipment'
          }
        ]
      },
      driverTypes: [],
      freightTypes: [],
      routeTypes: [],
      endorsements: [],
      experiences: [],
      states: []
    }
  },
  validations() {
    return {
      vacancyModel: {
        subject: {
          required: helpers.withMessage('Subject is required!', required),
          minLength: minLength(2),
          maxLength: maxLength(200)
        },
        stateId: {
          required
        },
        experienceId: {
          required
        },
        driverTypeIds: {
          required,
          minLength: minLength(1)
        },
        freightTypeIds: {
          required,
          minLength: minLength(1)
        },
        routeTypeIds: {
          required,
          minLength: minLength(1)
        },
        endorsementIds: {
          required,
          minLength: minLength(1)
        }
      }
    }
  }
}
</script>

<style>
  .page {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #0F0F0F;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: none;
  height: 45px;
  width: 45px;
}

.active-page {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  padding: 10px;
  background: #25A18E;
  color: #FFFFFF;
}

.btn-prev {
  padding: 10px;
  background: linear-gradient(94.3deg, #37266A 0%, #67357B 100%);
  border-radius: 4px;
  color: #FFFFFF;
}

.btn-next {
  padding: 10px;
  background: linear-gradient(94.3deg, #37266A 0%, #67357B 100%);
  border-radius: 4px;
  color: #FFFFFF;
}

.multiselect_container {
  align-items: center;
  background: rgba(231, 231, 235, 0.1);
  border-radius: var(--ms-radius, 8px);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: var(--ms-font-size, 1rem);
  justify-content: flex-end;
  margin: 0 auto;
  min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2);
  outline: none;
  position: relative;
  width: 100%;
  height: 60px;
}

.multiselect_options {
  color: #FFFFFF;
  background: #604180;
  border-radius: 4px;
}

.multiselect_search {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(231, 231, 235, 0);
  border: 0;
  border-radius: var(--ms-radius, 4px);
  bottom: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  left: 0;
  outline: none;
  padding-left: var(--ms-px, .875rem);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.multiple_label{
  color: #FFFFFF;
  align-items: center;
    background: transparent;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    left: 0;
    line-height: var(--ms-line-height,1.375);
    max-width: 100%;
    padding-left: var(--ms-px,.875rem);
    padding-right: calc(1.25rem + var(--ms-px, .875rem)*3);
    pointer-events: none;
    position: absolute;
    top: 0;
}
</style>