import axios from "axios"
import store from '@/store/index.js'

var getAuthorizedRequest = async function(){
  const token = store.getters.carrierAccessToken;
  return axios.create({
    baseURL: window.config.baseApiUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}
export default{
  getAuthorizedRequest
}