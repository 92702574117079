<template>
  <section class="driver_app_main">
    <div class="container">
      <div class="row">
        <div class="form_block form_chek_radio">
          <div class="row_form bot_divider">
            <!-- DRIVER TYPES -->
            <div class="col_form">
              <div class="col_title">Driver type <span>*</span></div>

              <div>
                <div
                  v-for="driverType in driverTypes"
                  :key="driverType.id"
                  class="form-check"
                >
                  <input
                    class="form-styled checkbox-styled"
                    type="checkbox"
                    :value="driverType.id"
                    v-model="driverData.driverTypeIds"
                    :id="`driver_${driverType.id}`"
                  />
                  <label :for="`driver_${driverType.id}`">
                    {{ driverType.type }}
                  </label>
                </div>
              </div>
            </div>

            <!-- EXPERIENCES -->
            <div class="col_form">
              <div class="col_title">Experience <span>*</span></div>
              <div>
                <div
                  v-for="experience in experiences"
                  :key="experience.id"
                  class="form-check"
                >
                  <input
                    type="radio"
                    :value="experience.id"
                    v-model="driverData.experienceId"
                    class="form-styled radio_styled"
                    :id="`experience_${experience.id}`"
                  />
                  <label :for="`experience_${experience.id}`">
                    {{ experience.value }}
                  </label>
                </div>
              </div>
            </div>

            <!-- ROUTE TYPES -->
            <div class="col_form">
              <div class="col_title">
                Route type <span>*</span>
                <p>Check all that apply</p>
              </div>

              <div>
                <div
                  v-for="routeType in routeTypes"
                  :key="routeType.id"
                  class="form-check"
                >
                  <input
                    class="form-styled checkbox-styled"
                    type="checkbox"
                    :value="routeType.id"
                    v-model="driverData.routeTypeIds"
                    :id="`routeType_${routeType.id}`"
                  />
                  <label :for="`routeType_${routeType.id}`">
                    {{ routeType.type }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- FREIGHT TYPES -->
          <div class="row_form">
            <div class="col_form">
              <div class="col_title">
                Freight type <span>*</span>
                <p>Check all that apply</p>
              </div>

              <div>
                <div
                  v-for="freightType in freightTypes"
                  :key="freightType.id"
                  class="form-check"
                >
                  <input
                    class="form-styled checkbox-styled"
                    type="checkbox"
                    :value="freightType.id"
                    v-model="driverData.freightTypeIds"
                    :id="`freightType_${freightType.id}`"
                  />
                  <label :for="`freightType_${freightType.id}`">
                    {{ freightType.type }}
                  </label>
                </div>
              </div>
            </div>

            <!-- ENDORSEMENTS -->
            <div class="col_form">
              <div class="col_title">Endorsements</div>
              <div>
                <div
                  v-for="endorsement in endorsements"
                  :key="endorsement.id"
                  class="form-check"
                >
                  <input
                    class="form-styled checkbox-styled"
                    type="checkbox"
                    :value="endorsement.id"
                    v-model="driverData.endorsementIds"
                    :id="`endorsement_${endorsement.id}`"
                  />
                  <label :for="`endorsement_${endorsement.id}`">
                    {{ endorsement.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="bot_row">
            <strong>
              Your application will be sent to the job(s) that you match with
            </strong>
            <button
              @click="apply"
              class="button"
              :class="{ but_blue: !this.v$.$invalid }"
              :disabled="this.v$.$invalid"
            >
              Apply now
            </button>
          </div>
        </div>

        <div class="driver_app_vacancy">
          <div class="info_items">
            <div class="hiring_now_driver_app_items">
              <h3>Hiring now</h3>
              <div class="label_top items_label_top"><p>Featured</p></div>
              <Vacancy
                v-for="vacancy in vacancies"
                :key=vacancy.id :vacancy="vacancy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <loading :active="isLoading" :can-cancel="true" />
  <div class="overlay hidden"></div>

  <!-- Modal notification -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img
        :src="require(`@/assets/images/${modal.selectedIcon}`)"
        alt="Notification icon"
      />
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="#" class="close btn-close" data-close="modals">⨉</a>
  </div>
</template>

<script>
import VacancyComponent from "../homeComponents/inComponent/vacancyComponent.vue";
import DriverTypeService from "@/services/driverTypeService.js";
import FreightTypeService from "@/services/freightTypeService.js";
import RouteTypeService from "@/services/routeTypeService.js";
import EndorsementService from "@/services/endorsementService.js";
import ExperienceService from "@/services/experienceService.js";
import DriverApplicationService from "@/services/driverApplicationService";
import VacancyService from "@/services/vacancyService.js";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  name: "DriverApplication2Component",
  mounted() {
    this.loadVacancy();
    this.loadDriverTypes();
    this.loadFreightTypes();
    this.loadRouteTypes();
    this.loadEndorsements();
    this.loadExperiences();
    this.loadHotVacancies();
  },
  components: {
    Loading,
    Vacancy: VacancyComponent,
  },
  methods: {
    loadDriverTypes: async function () {
      this.isLoading = true;
      var response = await DriverTypeService.getDriverTypes();
      this.driverTypes = response.data.items;
      this.isLoading = false;
    },
    loadFreightTypes: async function () {
      this.isLoading = true;
      var response = await FreightTypeService.getFreightTypes();
      this.freightTypes = response.data.items;
      this.isLoading = false;
    },
    loadRouteTypes: async function () {
      this.isLoading = true;
      var response = await RouteTypeService.getRouteTypes();
      this.routeTypes = response.data.items;
      this.isLoading = false;
    },
    loadEndorsements: async function () {
      this.isLoading = true;
      var response = await EndorsementService.getEndorsements();
      this.endorsements = response.data.items;
      this.isLoading = false;
    },
    loadExperiences: async function () {
      this.isLoading = true;
      var response = await ExperienceService.getExperiences();
      this.experiences = response.data.items;
      this.isLoading = false;
    },
    apply: async function () {
      var self = this;
      var response = {};

      this.isLoading = true;
      if (this.vacancyId == null || this.vacancyId == 0) {
        response = await DriverApplicationService.apply(self.driverData);
      } else {
        response = await DriverApplicationService.applyToFutured(
          self.vacancyId,
          self.driverData
        );
      }
      this.isLoading = false;

      if (response.data.successful) {
        self.toggleModal(
          self.modal.successIcon,
          "Success",
          "Thank you! Recruiters will contact you soon."
        );
        setTimeout(() => {
          self.$router.push({ name: "Home" });
        }, 3000);
      } else {
        self.toggleModal(
          self.modal.errorIcon,
          "Something went wrong",
          response.data.errors[0].messages[0] ??
            "Error happened while saving the data"
        );
      }
    },
    loadVacancy: async function () {
      var self = this;
      this.isLoading = true;
      var response = await VacancyService.get(self.searchModel);
      this.isLoading = false;
      this.vacancies = response.data.items;
    },
    loadHotVacancies: async function () {
      this.isLoading = true;
      var self = this;
      var response = await VacancyService.get(self.vacancySearchModel);
      this.isLoading = false;

      if (response.data.successful) {
        // console.log("DRIVER APPLICATON 2 => HOT VACANCIES LOADED");
        this.hotVacancies = response.data.items;
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add("open");
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove("open");
        overlay_modals.classList.add("hidden");
      }, 3000);
    },
  },
  data() {
    return {
      vacancies: [],
      searchModel: {
        companyName: "",
        stateName: "",
        experienceId: 0,
        driverTypeIdList: [],
        freightTypeIdList: [],
        routeTypeIdList: [],
        endorsementIdList: [],
        timeRange: "AllTime",
        page: 1,
        size: 6,
      },
      vacancySearchModel: {
        companyName: "",
        stateName: "",
        experienceId: 0,
        driverTypeIdList: [],
        freightTypeIdList: [],
        routeTypeIdList: [],
        endorsementIdList: [],
        timeRange: "AllTime",
        page: 1,
        size: 6,
      },
      isLoading: false,

      driverData: {
        driverId: this.registeredDriverId,
        hasCdl: true,
        experienceId: null,
        driverTypeIds: [],
        freightTypeIds: [],
        routeTypeIds: [],
        endorsementIds: [],
      },

      hotVacancies: [],
      driverTypes: [],
      freightTypes: [],
      routeTypes: [],
      endorsements: [],
      experiences: [],
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg",
      },
    };
  },
  props: ["vacancyId", "registeredDriverId"],
   
  validations() {
    return {
      driverData: {
        experienceId: {
          required,
        },
        driverTypeIds: {
          required,
          minLength: minLength(1),
        },
        freightTypeIds: {
          required,
          minLength: minLength(1),
        },
        routeTypeIds: {
          required,
          minLength: minLength(1),
        },
      },
    };
  },
};
</script>
