import { createRouter, createWebHistory } from 'vue-router'
import homeView from '../views/homeView.vue'
import CarrierRegisterView from '../views/carrierRegisterView.vue'
import DriverApplicationView from '../views/driverApplicationView.vue'
import HiringNowView from '../views/hiringNowView.vue'
import AboutUsView from '../views/aboutUsView.vue'
import DriverApplication2View from '@/views/driverApplication2View';
import CarrierLoginView from '@/views/carrierLoginView.vue';
import CarrierCreateVacancyView from '@/views/carrierCreateVacancyView.vue';
import pricingView from '@/views/pricingView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: homeView
  },
  {
    path: '/carrier-register',
    name: 'Carrier Register',
    component: CarrierRegisterView
  },
  {
    path: '/driver-application/:vacancyId',
    name: 'Driver Application',
    component: DriverApplicationView,
    props: true
  },
  {
    path: '/hiring-now',
    name: 'Hiring Now',
    component: HiringNowView
  },
  
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUsView
  },
  {
    path: '/driver-application2/:registeredDriverId/:vacancyId',
    name: 'DriverApplication2',
    component: DriverApplication2View,
    props: true
  },
  {
    path: '/carrier-login',
    name: 'CarrierLogin',
    component: CarrierLoginView
  },
  {
    path: '/carrier-create-vacancy',
    name: 'CarrierCreateVacancy',
    component: CarrierCreateVacancyView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: pricingView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior() {
    // always scroll to top
    return {
      
      top: 0 }
    
  }
})

export default router
