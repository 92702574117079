<template>
<section class="home_top">
    <div class="container">
      <div class="col_left">
        <h1>
          Your dream job is just <br> a click away
        </h1>
        <p>
          Connect with trucking companies in a safe, innovative and effective way by filling out the driver application and choose the best offer that matches your expectation
        </p>
        <div class="bot_btn">
          <router-link to="/driver-application/0" class="button but_white">Driver application</router-link>
          <router-link to="/carrier-register" class="button but_bordered">Carrier register</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style >

</style>