<template>
  <section class="aboutP_top">
    <div class="container">
      <div class="aboutP_wrap">
        <img src="@/assets/images/logo.svg" alt="findCDL">
        <div class="col_right">
          Recruiting from A to Z
        </div>
      </div>
    </div>
  </section>
  <section class="aboutP_info">
    <div class="container">
      <div class="row_block_1">
        <div>
          Finding experienced drivers is hard work, and for many trucking companies, it’s becoming increasingly difficult. Fortunately, 
          your virtual recruiter can step in and make the entire hiring process significantly easier for businesses of all sizes.
          <br><br>
          Partner carriers will have access to all oncoming leads through their online account and make the hiring procedure flow 
          more smoothly without stress for the company's HR team.
        </div>
      </div>
      <div class="row_block_2">
        <div>
          As this platform serves as liaison between qualified candidates and employers, our marketing strategy emphasizes on a real-time talent market data. 
          Applicants will be provided with multiple options by submitting simple and most importantly, free application form.
          <br><br>
          This innovative recruitment tool offers various advantages for the employers in order to make the hiring process more efficient and less expensive.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>