<template>
  <title-green-component />
  <carrier-register-main-component />
</template>

<script>
import CarrierRegisterMainComponent from '@/components/carrierRegisterComponents/carrierRegisterMainComponent.vue';
import TitleGreenComponent from '@/components/carrierRegisterComponents/titleGreenComponent.vue';

export default {
  components: { TitleGreenComponent, CarrierRegisterMainComponent }
}
</script>

<style lang="scss" scoped>

</style>
