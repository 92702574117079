<template>
  <section class="title_block">
    <div class="container">
      <h1>
        Let Your virtual recruiter find <br>
        the best options for You
      </h1>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>