<template>
  <header :class="navigationColor">
    <div class="container head_wrap">
      <div class="head_left">
        <a class="logo" href="/">
          <img src="@/assets/images/logo.svg" alt="findCDL">
        </a>
        <div class="head_social">
          <a target="_blank" href="https://www.instagram.com/findcdl_recruiting/"><img src="@/assets/images/instagram.svg" 
            alt="instagram"></a>
          <a target="_blank" href="https://www.facebook.com/findcdlrecruiting/"><img
              src="@/assets/images/facebook.svg" alt="facebook"></a>

          <a target="_blank" href="https://www.tiktok.com/@findcdl.com"><img src="@/assets/images/tiktok.svg"
              alt="tiktok"></a>
        </div>
      </div>
      <div class="head_but">
        <router-link to="/hiring-now" class="button but_fire">Hiring now</router-link>
        <router-link to="/about-us" class="button but_grey">Insights</router-link>
        <router-link to="/driver-application/0" class="button but_grey">Driver application</router-link>
        <router-link to="/pricing" class="button but_grey">Pricing</router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {

  data() {
    return {
      navigationColor: "",
    }
  },
  // TO DO
  computed: {
    isLoggedInAsCarrier() {
      let token = this.$store.carrierAccessToken;
      return token !== null;
    }
  },
  watch: {
    $route(to) {
      if (to.name.toLowerCase().indexOf("home") === -1) {
        this.navigationColor = "nav_color";

      } else {
        this.navigationColor = "";

      }
    }
  }

}
</script>

<style >

</style>