<template>
  <div class="plan_block">
    <!-- Choose plan -->
    <ChoosePlanCardComponet v-for="subscription in subscribtions" 
    :key="subscription.id" 
    :image="subscription.imgSrc"
    :name1="subscription.planName"
    :name2="subscription.planName2"
    :name3="subscription.planName3"
    :words="subscription.words"
    />
  </div>

</template>

<script>
import ChoosePlanCardComponet from './companyChoosePlanCardComponent.vue/choosePlanCardComponet.vue';

// Services


export default {
  mounted() {
   
  },
  components: {
    ChoosePlanCardComponet
  },
  methods: {
    
  },
  data() {
    return {
      // Response models
      subscriptionPlans: [],
      subscribtions: [
        {
          id: 1,
          imgSrc: null,
          planName: "Basic Plan",
          planName2: "(paid per direct lead)",
          planName3: "Hire on a budget per lead contract",
          words: [{
            id: 1,
            name: "Company profile",
          },
          {
            id: 2,
            name: "Admin and sub users",
          },
          {
            id: 2,
            name: "Statistics dashboard",
          },
          {
            id: 3,
            name: "Create hiring requirements",
          },
          {
            id: 4,
            name: "Standard Customer Service",
          },
          {
            id: 6,
            name: "Email alerts when new candidates match your criteria",
          }]
        },
        {
          id: 2,
          imgSrc: "popular.svg",
          planName: "Standard Plan",
          planName2: "(monthly)",
          planName3: "Unlimited candidate applications",
          words: [{
            id: 1,
            name: "Company profile",
          },
          {
            id: 2,
            name: "Admin and sub users",
          },
          {
            id: 2,
            name: "Statistics dashboard",
          },
          {
            id: 3,
            name: "Create hiring requirements",
          },
          {
            id: 4,
            name: "Enhanced Customer Service",
          },
          {
            id: 5,
            name: "Unlimited leads",
          },
          {
            id: 6,
            name: "Email alerts when new candidates match your criteria",
          }
        ]
        },
        {
          id: 3,
          imgSrc: "featured.svg",
          planName: "Premium Plan",
          planName2: "(monthly)",
          planName3: "Boost your reach and visibility",
          words: [{
            id: 1,
            name: "Company profile",
          },
          {
            id: 2,
            name: "Admin and sub users",
          },
          {
            id: 2,
            name: "Statistics dashboard",
          },
          {
            id: 3,
            name: "Create hiring requirements",
          },
          {
            id: 4,
            name: "Expert Customer Service & Guidance",
          },
          {
            id: 5,
            name: "Unlimited leads",
          },
          {
            id: 6,
            name: "Email alerts when new candidates match your criteria",
          },
          {
            id: 7,
            name: "Create Customized Featured Ads",
          },
          {
            id: 8,
            name: "Sponsored Advertisement",
          }
          ]
        }
      ],
      
    }
  }
}
</script>

<style >

</style>