<template>
  <section class="talk_us">
    <div class="container">
      <div class="inner_block">
        <h3>
          Talk to Us!
        </h3>
        <p>
          Anything you would like to let us now?
        </p>
        <router-link class="button but_blue" to="/carrier-register">Contact </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style >

</style>