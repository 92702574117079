<template>
  <about-top-component />
  <about-bottom-component />
</template>

<script>
import AboutBottomComponent from '@/components/aboutUsComponents/aboutBottomComponent.vue';
import AboutTopComponent from '@/components/aboutUsComponents/aboutTopComponent.vue';

export default {
  components: { AboutTopComponent, AboutBottomComponent }
}
</script>

<style lang="scss" scoped>

</style>