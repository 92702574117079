<template>
  <section class="title_block title_green">
    <div class="container">
      <h1>
        Join the club to find your Drivers
      </h1>
      <span>Are you looking to hire qualified drivers? Fill out the form below to be contacted with our top
        consultants!</span>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>