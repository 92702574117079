import axios from "axios";
import errorHandler from "@/helpers/errorHandler";

export default {
    getFreightTypes: async function() {
        
        var instance = axios.create({
            baseURL: window.config.baseApiUrl
        });

        var response = {}

        await instance.get("/api/freightType/get")
        .then((res) => {
            response = res;
        })
        .catch(async function(error) {
            response = await errorHandler.handleRequestError(error);
        });
        return response;
    }
}
