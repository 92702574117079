/* eslint-disable */
import axios from "axios";
import errorHandler from "@/helpers/errorHandler";
import mainService from "@/services/mainService.js";

export default {

  get: async function (query) {
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        "Content-Type": "application/json"
      }
    });

    let url = `/api/vacancy/search?companyName=${query.companyName}&stateName=${query.stateName}&page=${query.page}&size=${query.size}&timeRange=${query.timeRange}&experienceId=${query.experienceId}`;    

    query.driverTypeIdList.forEach(x => {
      url += `&driverTypeIdList=${x}`;
    });

    query.freightTypeIdList.forEach(x => {
      url += `&freightTypeIdList=${x}`;
    });

    query.routeTypeIdList.forEach(x => {
      url += `&routeTypeIdList=${x}`;
    });

    query.endorsementIdList.forEach(x => {
      url += `&endorsementIdList=${x}`;
    });

    var response = {}
    await instance.get(url)
    .then((res) => {
      response = res;
    })
      .catch(async function (error) {
        response = await errorHandler.handleRequestError(error);
      });

    return response;
  },


  createVacancy: async function(vacancyModel) {
    var instance = await mainService.getAuthorizedRequest();

    var response = {};

    await instance.post('/api/vacancy/createVacancy', vacancyModel)
    .then(res => {
      response = res; 
    })
    .catch(async error => {
      response = errorHandler.handleRequestError(error);
    });

    return response;
  }
}
