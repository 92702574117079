import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
// TO DO - UNCOMMENT BEFORE DEPLOYING
import { config } from '@/configuration/config'
// import { config } from '@/configuration/config.js';
import '@/assets/css/styles.css'

// PAGINATION
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

window.config = config;
window.store = store;

createApp(App)
    .use(moshaToast)
    .use(Maska)
    .use(store)
    .use(router)
    .use(VueAwesomePaginate)
    .mount('#app');