<template>
  <section class="blue_back">
    <div class="container">
      <h3>
        Fill out a simple form <br>
        and You are one step closer to getting employed
      </h3>
      <router-link class="button but_green " to="/driver-application/0">Driver application</router-link>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>