<template>
  <div class="item_block">
    <div :class="{ label_top_visible: !vacancyIsFutured }" class="label_top">
      <p>Featured</p>
    </div>
    <div class="title" v-if="vacancy.subject">
      {{ (vacancy.subject.length > 50 ? vacancy.subject.substring(0,50) + '...' : vacancy.subject) }}
    </div>
    <ul class="block_txt">
      <li v-for="offer in firstThreeOffers" :key="offer.id">{{ offer.value }}</li>
    </ul>
    <div class="bot_block">
      <div class="company_logo">
        <!-- TO DO - download company image dynamically -->
        <img :src="`data:image/png;base64,${vacancy.logo}`" alt="">
      </div>
      <div class="block_but">
        <button @click="getDetailedInfo" data-toggle="modals" :data-target="'#'+modalName"
          class="button but_bordered">Details</button>
        <button @click="applyNow" class="button but_blue">Apply now</button>
      </div>
    </div>
  </div>

  <section class="modals modal_more_info" :id="modalName">
    <div class="modal_more_info_wrap">
      <div style="position: absolute;top: 10px;right: 20px;">
        <a href="#" class="close btn-close" data-close="modals">⨉</a>
      </div>
      <div class="modal_body_vacancy">
        <div class="modal_top">
        <img :src="`data:image/png;base64,${vacancy.logo}`" alt="">
        <!-- <a href="#" class="close btn-close" data-close="modals">⨉</a> -->
      </div>
      <div class="modal_body_vacancy_right">
        <p class="modals_title">
          {{ vacancy.subject }}
        </p>
        <ul>
          <li v-for="offer in vacancy.offers" :key="offer.id">{{ offer.value }}</li>
        </ul>
      </div>
      </div>
      <div class="modal_content">
        <p class="small_title">
          States
        </p>
        <p class="small_txt">
          <!-- Maryland, Pennsylvania, Illinois, Missouri, North Carolina, South Carolina, Ohio, Texas, Alabama, Arkansas,
          Georgia, Illinois, Indiana, Kentucky, Louisiana, Missouri -->
          {{ vacancy.stateName }}
        </p>
      </div>
      <div class="modal_foot">
        <a href="#" class="button but_bordered close" data-close="modals">Close</a>
        <router-link :to="`/driver-application/${vacancy.id}`" href="#" class="button but_blue">Apply now</router-link>
      </div>
    </div>
  </section>
  <div class="overlay hidden"></div>
</template>

<script>
export default {
  props:["vacancy", "isFutured"],
  data() {
    return {
      
    }
  },
  computed: {
    firstThreeOffers() {
      return this.vacancy.offers.slice(0, 3)
    },
    modalName: function(){
      return "modals" + this.vacancy.id;
    },
    vacancyIsFutured: function(){
      return this.vacancy.isFutured;
    }
  },
  methods: {
    applyNow: function() {
      if(this.vacancy.isFutured) {
        var vacancyId = this.vacancy.id;
        this.$router.push({ 
          path: `/driver-application/${vacancyId}`
        });
      } else {
        this.$router.push({ 
          path: `/driver-application/0`
        });
      }
    }
  },
  mounted() {
    document.addEventListener('click', function (e) {
      const overlay_modals = document.querySelector(".overlay");
      let $target = e.target;
      if ($target.closest('[data-toggle="modals"]')) {
        e.preventDefault();
        $target = $target.closest('[data-toggle="modals"]');
        document.querySelector($target.dataset.target).classList.add('open');
        overlay_modals.setAttribute("data-closeoverlay", $target.dataset.target);
        overlay_modals.classList.remove("hidden");

      } else if ($target.dataset.close === 'modals') {
        e.preventDefault();
        $target.closest('.modals').classList.remove('open');
        overlay_modals.classList.add("hidden");

      }
      else if ($target.dataset.closeoverlay != null) {
        e.preventDefault();
        document.querySelector($target.dataset.closeoverlay).classList.remove('open');
        overlay_modals.classList.add("hidden");
        $target.dataset.closeoverlay = null;
      }
    });
    // console.log("logging... ");
    // console.log(this.vacancy);
  }
}
</script>
