<template>
  <section class="single_title">
    <div class="container">
      You are one step closer to get employer
    </div>
  </section>
  <section class="aboutP_bot_wrap">
    <div class="container">
      <div class="aboutP_bot">
        <div class="col_left">
          <h1>
            Let Your virtual recruiter find the best options for You
          </h1>
        </div>
        <div class="col_right">
          <router-link class="rounded_bun" to="/driver-application/0">
            <span>
              Driver Application
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>