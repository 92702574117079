<template>
    <!-- Required Stylesheets -->
    <link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap/dist/css/bootstrap.min.css" />
    <link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css" />

    <form @submit.prevent="login">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email address</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                v-model="loginModel.email">
        </div>
        <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1" v-model="loginModel.password">
        </div>
        <button type="submit" class="btn btn-primary">Login</button>

        <div>
            <loading :active="isLoading" :can-cancel="true" />
        </div>

    </form>
</template>

<script>
import authService from '@/services/authenticationService';
// SPINNER
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'CarrierLoginComponent',
    components: {
        Loading
    },
    methods: {
        login: async function () {
            var self = this;
            this.isLoading = true;
            var response = await authService.loginUser(self.loginModel);
            this.isLoading = false;

            if (response.data.successful) {
                this.$store.commit('authenticateUser', {
                    carrierAccessToken: response.data.item
                });
                this.$moshaToast({
                    title: `Successfully logged in!`,
                    description: 'You can create a vacancy'
                },
                    {
                        type: 'success',
                        position: 'top-center',
                        transition: 'zoom',
                        timeout: '3000',
                        hideProgressBar: 'false',
                        toastBackgroundColor: '#339900',
                        showIcon: 'true',
                        showCloseButton: 'false'
                    }
                );


                this.$router.push({
                    path: '/carrier-create-vacancy'
                })
            }
            else {
                this.$moshaToast({
                    title: `Invalid credentials!`,
                    description: 'Enter the correct credentials'
                },
                    {
                        type: 'danger',
                        position: 'top-center',
                        transition: 'zoom',
                        timeout: '3000',
                        hideProgressBar: 'false',
                        toastBackgroundColor: '#cc3300',
                        showIcon: 'true',
                        showCloseButton: 'false'
                    }
                );
              
            }
        }
    },
    data() {
        return {
            isLoading: false,
            loginModel: {
                email: '',
                password: ''
            }
        }
    }
}
</script>

<style>

</style>
