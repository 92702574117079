<template>
  <hiring-filter-component />
</template>

<script>
import HiringFilterComponent from '@/components/hiringNowComponents/hiringFilterComponent.vue';

export default {
  components: {
    HiringFilterComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
