<template>
  <footer :class="currentClass">
    <div class="container">
      <div class="foot_left">
        <a href="/">
          <img :src="require(`@/assets/images/${imgSrc}`)" alt="findCDL">
        </a>
      </div>

      <div class="copy_block">
        <a href="#">
          Privacy Policy
        </a>
        <span> © {{ currentYear }} Find CDL</span>
        <a href="https://business.findcdl.com/login" target="_blank" style="cursor: pointer;" v-if="!isLoggedIn" >Login as a carrier</a>
        <a style="cursor: pointer; margin-right: 0;" v-if="isLoggedIn" @click="redirectToCreateVacancyPage">Create a
          vacancy</a>
        <a style="cursor: pointer;" v-if="isLoggedIn" @click="logout">Logout</a>
      </div>
      <div class="foot_social">
        <a target="_blank" href="https://www.instagram.com/findcdl_recruiting/"><img src="@/assets/images/instagram.svg"
          alt="instagram"></a>
        <a target="_blank" href="https://www.facebook.com/findcdlrecruiting/"><img
            src="@/assets/images/facebook.svg" alt="facebook"></a>

        <a target="_blank" href="https://www.tiktok.com/@findcdl.com"><img src="@/assets/images/tiktok.svg"
            alt="tiktok"></a>
      </div>
    </div>
  </footer>
  <section class="mobile_menu">
    <router-link to="/">
      <img src="@/assets/images/home.svg" alt="home">
      <span>Main</span>
    </router-link>
    <router-link to="/hiring-now">
      <img src="@/assets/images/flame.svg" alt="Hiring now">
      <span>Hiring now</span>
    </router-link>
    <a href="#" class="modal_menu_open">
      <img src="@/assets/images/menu.svg" alt="Menu">
      <span>Menu</span>
    </a>
  </section>
  <section class="modal_menu hidden">
    <router-link to="/driver-application/0"><img src="@/assets/images/truck.svg" alt="Driver application"> Driver
      application</router-link>
    <router-link to="/pricing"><img src="@/assets/images/clipboard.svg" alt="Driver application">Pricing</router-link>
    <router-link to="/about-us"><img src="@/assets/images/info.svg" alt="Driver application">Insights</router-link>
    <router-link to="/carrier-register"><img src="@/assets/images/mail.svg" alt="Driver application">Contacts
    </router-link>
  </section>
  <div class="overlay hidden"></div>

</template>

<script>
export default {
  methods: {
    logout: function () {
      this.$store.commit('logOutCarrier');
    },
    redirectToLoginPage: function () {
      this.$router.push({
        path: '/carrier-login'
      })
    },
    redirectToCreateVacancyPage: function () {
      this.$router.push({
        path: '/carrier-create-vacancy'
      })
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.carrierAccessToken;
    }
  },
  watch: {
    $route(to) {
      if (to.name.toLowerCase().indexOf("about us") === -1) {
        this.currentClass = "";
        this.imgSrc = "logo.svg"
      } else {
        this.currentClass = "foot_dark";
        this.imgSrc = "logo_white.svg"
      }
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentClass: "foot_dark",
      imgSrc: "logo.svg"
    }
  }
}
</script>

<style>
.copy_block span {
  margin-right: 30px;
}
</style>