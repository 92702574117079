<template>
  <div class="adv_item">
    <img :src="require(`@/assets/images/${imgSrc}`)" alt="Hiring All in One">
    <p class="title">{{headerText}}</p>
    <p>
      {{innerText}}
    </p>
  </div>
</template>

<script>
export default {
  props: ['imgSrc', 'headerText', 'innerText'],
}
</script>

<style lang="scss" scoped>

</style>