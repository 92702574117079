<template>
  <section class="partner_logo">
    <swiper :modules="modules" :slides-per-view="5" :breakpoints="{
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      }, 
      480: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1024:{
        slidesPerView: 4,
        spaceBetween: 35
      },
      1300:{
        slidesPerView: 5,
        spaceBetween: 60
      },
      1600:{
        slidesPerView: 6,
        spaceBetween: 90
      }
    }" :autoplay="{
  delay: 5000,
  disableOnInteraction: false,
}" :space-between="50" navigation :pagination="{ clickable: true }" @swiper="onSwiper"
      @slideChange="onSlideChange">
      <swiper-slide> <img src="@/assets/images/logo-1.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-2.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-3.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-4.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-5.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-6.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-7.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-1.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-2.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-3.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-4.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-5.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-6.png" alt="logo"> </swiper-slide>
      <swiper-slide> <img src="@/assets/images/logo-7.png" alt="logo"> </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
     return swiper;
    };
    const onSlideChange = () => {
      return onSlideChange;
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],

    };
  },
}
</script>

<style>
.swipper-slide img{
  width: 280px;
}
</style>