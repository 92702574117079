<template>
  <navigation-bar-component />
  <router-view />
  <talk-us-component />
  <footer-componet />
</template>

<script>
import NavigationBarComponent from '@/components/shared/navigationBarComponent.vue'
import TalkUsComponent from '@/components/shared/talkUsComponent.vue'
import FooterComponet from '@/components/shared/footerComponent.vue'

export default {
  components: {
    NavigationBarComponent,
    TalkUsComponent,
    FooterComponet
  },
  created() {
    this.$store.commit('initializeState');
  },
  mounted() {
    /* eslint-disable */
    const modal_menu = document.querySelector(".modal_menu");
    const openModalBtn_menu = document.querySelector(".modal_menu_open");
    const overlay = document.querySelector(".overlay");
    const closeModalBtn = document.querySelector(".btn-close");

    // close modal function
    const closeModal_menu = function () {
      modal_menu.classList.add("hidden");
      overlay.classList.add("hidden");
    };

    // close the modal when the close button and overlay is clicked
    modal_menu.addEventListener("click", closeModal_menu);
    overlay.addEventListener("click", closeModal_menu);

    // close modal when the Esc key is pressed
    document.addEventListener("keydown", function (e) {
      if (e.key === "Escape" && !modal_menu.classList.contains("hidden")) {
        closeModal_menu();
      }
    });

    // open modal function
    const openModal_menu = function () {
      modal_menu.classList.remove("hidden");
      overlay.classList.remove("hidden");
    };
    // open modal event
    openModalBtn_menu.addEventListener("click", openModal_menu);
    modal_menu.addEventListener("click", closeModal_menu);
  }
}
</script>
<style>

</style>