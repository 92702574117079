<template>
    <CarrierLogin></CarrierLogin>
</template>

<script>
    import CarrierLoginComponent from '@/components/carrierLoginComponents/carrierLoginComponent.vue'

    export default {
        name: 'CarrierLoginView',
        components: {
            CarrierLogin: CarrierLoginComponent
        }
    }

</script>
