<template>
  <section class="hiring_filter">

    <div class="container">
      <h1>
        Find the right job for you
      </h1>

      <div class="form_block">
        <div class="row_form">
          <div class="col_form">
            <label class="form-label">Company name</label>
            <input type="text" class="form-control" v-model="searchModel.companyName">
          </div>

          <div class="col_form">
            <label class="form-label">Where</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="single" :options="states" :openDirection="styleMult" valueProp="name" label="name"
              v-model="searchModel.stateName" :hide-selected="false" :close-on-select="true"
              noOptionsText="No state found!" noResultsText="No state found!" :multiple="false" :taggable="false"
              placeholder="States" />
          </div>
        </div>
        <div class="row_form hide_800">

          <!-- DRIVER TYPES -->
          <div class="col_form">
            <label class="form-label">Driver type</label>

            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="driverTypes" valueProp="id" label="type"
              v-model="searchModel.driverTypeIdList" :searchable="false" :caret="true" :hide-selected="false"
              :close-on-select="false" noOptionsText="No driver types found!" noResultsText="No driver types found!" />
          </div>

          <!-- EXPERIENCES -->
          <div class="col_form">
            <label class="form-label">Experience level</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" :options="experiences" valueProp="id" label="value" v-model="searchModel.experienceId"
              :searchable="false" :caret="true" :hide-selected="false" :close-on-select="true"
              noOptionsText="No experiences found!" noResultsText="No experiences found!" />
          </div>



          <!-- ROUTE TYPES -->
          <div class="col_form">
            <label class="form-label">Route type</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="routeTypes" valueProp="id" label="type" v-model="searchModel.routeTypeIdList"
              :searchable="false" :caret="true" :hide-selected="false" :close-on-select="false"
              noOptionsText="No route types found!" noResultsText="No route types found!" />
          </div>
        </div>


        <!-- FREITH TYPES -->
        <div class="row_form hide_800">
          <div class="col_form">
            <label class="form-label">Freight type</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="freightTypes" valueProp="id" label="type"
              v-model="searchModel.freightTypeIdList" :searchable="false" :caret="true" :hide-selected="false"
              :close-on-select="false" noOptionsText="No freight types found!" noResultsText="No freight types found!" />
          </div>

          <!-- ENDORSEMENTS -->
          <div class="col_form">
            <label class="form-label">Endorsements</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="endorsements" valueProp="id" label="name"
              v-model="searchModel.endorsementIdList" :searchable="false" :caret="true" :hide-selected="false"
              :close-on-select="false" noOptionsText="No endorsements found!" noResultsText="No endorsements found!" />
          </div>
          <div class="col_form">
            <label class="form-label">Date posted</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="single" :options="datePostedValues" valueProp="id" label="name" v-model="searchModel.timeRange"
              :caret="true" :hide-selected="false" :close-on-select="true" noOptionsText="No time frame!"
              noResultsText="No such time frame!" />
          </div>
        </div>
        <div class="block_but">
          <a href="#" class="button but_bordered advanced_search">Advanced search</a>
          <a @click="reset" href="#" class="button but_bordered hide_800">Reset</a>
          <a @click="find" class="button but_white but_find" href="#vacancy">
            Find
            <img src="@/assets/images/search.svg" alt="find">
          </a>
        </div>

      </div>
    </div>

  </section>

  <section class="hiring_now" id="vacancy">
    <div class="container">

      <div class="hiring_now_items">
        <!-- FOUND VACANCIES -->
        <Vacancy v-for="vacancy in featuredVacancies" :key="vacancy.id" :vacancy="vacancy">
        </Vacancy>
      </div>

      <!-- PAGINATION -->
      <vue-awesome-paginate v-if="vacanciesLoaded" style="display: flex; justify-content: center;"
        :total-items="vacancies.totalCount" v-model="searchModel.page" :items-per-page="searchModel.size"
        :max-pages-shown="4" paginationContainerClass="pagination" paginateButtonsClass="page"
        active-page-class="active-page" back-button-class="prev-next-btn" next-button-class="prev-next-btn"
        :on-click="searchForPage" :hide-prev-next-when-ends="true" />
    </div>

    <div>
      <loading :active="isLoading" :can-cancel="true" />
    </div>

  </section>

  <section class="modal hidden">
    <div class="modal_body">
      <div class="modal_title">
        <h3>
          Advanced search
        </h3>
        <a class="btn-close">⨉</a>
      </div>
      <div class="modal_content">
        <div class="form_block" ref="advancedSearch">
          <div class="col_form">
            <label class="form-label">Company name</label>
            <input type="text" class="form-control" v-model="searchModel.companyName">
          </div>
          <div class="col_form form-address">
            <label class="form-label">Where</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="single" :options="states" :openDirection="styleMult" valueProp="id" label="name"
              v-model="searchModel.stateName" :hide-selected="false" :close-on-select="true"
              noOptionsText="No state found!" noResultsText="No state found!" :multiple="false" :taggable="false"
              placeholder="States" />
          </div>

          <div class="col_form">
            <label class="form-label">Driver type</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="driverTypes" valueProp="id" openDirection="top" label="type"
              v-model="searchModel.driverTypeIdList" :searchable="false" :caret="true" :hide-selected="false"
              :close-on-select="false" noOptionsText="No driver types found!" noResultsText="No driver types found!" />
          </div>
          <div class="col_form">
            <label class="form-label">Experience level</label>

            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" :options="experiences" valueProp="id" label="value" v-model="searchModel.experienceId"
              openDirection="top" :searchable="false" :caret="true" :hide-selected="false" :close-on-select="true"
              noOptionsText="No experiences found!" noResultsText="No experiences found!" />
          </div>
          <div class="col_form">
            <label class="form-label">Route type</label>

            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="routeTypes" valueProp="id" openDirection="top" label="type"
              v-model="searchModel.routeTypeIdList" :searchable="false" :caret="true" :hide-selected="false"
              :close-on-select="false" noOptionsText="No route types found!" noResultsText="No route types found!" />
          </div>

          <div class="col_form">
            <label class="form-label">Freight type</label>

            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="freightTypes" valueProp="id" label="type"
              v-model="searchModel.freightTypeIdList" openDirection="top" :searchable="false" :caret="true"
              :hide-selected="false" :close-on-select="false" noOptionsText="No freight types found!"
              noResultsText="No freight types found!" />
          </div>
          <div class="col_form">
            <label class="form-label">Endorsements</label>

            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="multiple" :options="endorsements" valueProp="id" label="name"
              v-model="searchModel.endorsementIdList" openDirection="top" :searchable="false" :caret="true"
              :hide-selected="false" :close-on-select="false" noOptionsText="No endorsements found!"
              noResultsText="No endorsements found!" />
          </div>
          <div class="col_form">
            <label class="form-label">Date posted</label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search'
            }" mode="single" :options="datePostedValues" openDirection="top" valueProp="id" label="name"
              v-model="searchModel.timeRange" :hide-selected="false" :close-on-select="true"
              noOptionsText="No time frame!" noResultsText="No such time frame!" :multiple="false" :taggable="false" />
          </div>
        </div>
      </div>
      <div class="modal_footer">
        <a href="#" class="button but_bordered" @click="reset">Reset</a>
        <a href="#vacancy" class="button but_blue but_find button_modal_close" @click="find">Find
          <img src="@/assets/images/search_white.svg" alt="find">
        </a>
      </div>
    </div>
  </section>

  <div class="overlay hidden"></div>

  <!-- Modal notification -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="#" class="close btn-close" data-close="modals">⨉</a>
  </div>

</template>

<script>
import VacancyComponent from '@/components/homeComponents/inComponent/vacancyComponent.vue';
import StateService from '@/services/stateService.js';
import DriverTypeService from '@/services/driverTypeService.js';
import FreightTypeService from '@/services/freightTypeService.js';
import RouteTypeService from '@/services/routeTypeService.js';
import EndorsementService from '@/services/endorsementService.js';
import ExperienceService from '@/services/experienceService.js';
import VacancyService from '@/services/vacancyService.js';
import "vue-awesome-paginate/dist/style.css";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";

export default {
  mounted() {
    var self = this;
    addEventListener('keypress', function (e) {
      if (e.key === "Enter") {
        // console.log("ENTER");
        self.find();
      }
    });

    addEventListener("resize", () => {
      var w = window.innerWidth;
      if (w < 799) {
        this.styleMult = "top"
      } else {
        this.styleMult = "bottom"
      }
    });
    this.loadStates();
    this.loadDriverTypes();
    this.loadFreightTypes();
    this.loadRouteTypes();
    this.loadEndorsements();
    this.loadExperiences();
    this.search();

    const modal = document.querySelector(".modal");
    const overlay = document.querySelector(".overlay");
    const openModalBtn = document.querySelector(".advanced_search");
    const closeModalBtn = document.querySelector(".btn-close");
    const closeFindModalBtn = document.querySelector(".button_modal_close");
    const mobileMenu = document.querySelector(".mobile_menu");
    // close modal function
    const closeModal = function () {

      mobileMenu.classList.remove("hidden");
      modal.classList.add("hidden");
      overlay.classList.add("hidden");
    };

    // close the modal when the close button and overlay is clicked
    closeModalBtn.addEventListener("click", closeModal);
    closeFindModalBtn.addEventListener("click", closeModal);
    overlay.addEventListener("click", closeModal);

    // close modal when the Esc key is pressed
    document.addEventListener("keydown", function (e) {
      if (e.key === "Escape" && !modal.classList.contains("hidden")) {
        closeModal();
      }
    });

    // open modal function
    const openModal = function () {
      modal.classList.remove("hidden");
      overlay.classList.remove("hidden");
      mobileMenu.classList.add("hidden");
    };
    // open modal event
    openModalBtn.addEventListener("click", openModal);
  },
  components: {
    Vacancy: VacancyComponent,
    Loading,
    Multiselect
  },
  methods: {
    loadStates: async function () {
      this.isLoading = true;
      var response = await StateService.getStates();
      this.states = response.data.items;
      this.isLoading = false;
    },
    reset: function () {
      this.searchModel.companyName = '',
      this.searchModel.stateName = '';
      this.searchModel.experienceId = 0;
      this.searchModel.driverTypeIdList = [];
      this.searchModel.freightTypeIdList = [];
      this.searchModel.routeTypeIdList = [];
      this.searchModel.endorsementIdList = [];
      this.searchModel.timeRange = 'AllTime';
    },
    loadDriverTypes: async function () {
      this.isLoading = true;
      var response = await DriverTypeService.getDriverTypes();
      this.driverTypes = response.data.items;
      this.isLoading = false;
    },
    loadFreightTypes: async function () {
      this.isLoading = true;
      var response = await FreightTypeService.getFreightTypes();
      this.freightTypes = response.data.items;
      this.isLoading = false;
    },
    loadRouteTypes: async function () {
      this.isLoading = true;
      var response = await RouteTypeService.getRouteTypes();
      this.routeTypes = response.data.items;
      this.isLoading = false;
    },
    loadEndorsements: async function () {
      this.isLoading = true;
      var response = await EndorsementService.getEndorsements();
      this.endorsements = response.data.items;
      this.isLoading = false;
    },
    loadExperiences: async function () {
      this.isLoading = true;
      var response = await ExperienceService.getExperiences();
      this.experiences = response.data.items;
      this.isLoading = false;
    },
    search: async function () {
      if (!this.searchModel.experienceId) {
        this.searchModel.experienceId = 0;
      }
      
      this.isLoading = true;
      var response = await VacancyService.get(this.searchModel);
      this.isLoading = false;

      if (response.data.successful) {
        this.vacancies.items = response.data.items;
        this.vacancies.totalCount = response.data.totalCount;
        this.vacancies.pageCount = Math.trunc(this.vacancies.totalCount % this.searchModel.size === 0 ? this.vacancies.totalCount / this.searchModel.size : this.vacancies.totalCount / this.searchModel.size + 1);

        if (this.vacancies.items.length === 0) {
          this.vacanciesLoaded = false;
          this.toggleModal(this.modal.warningIcon, "Warning", "No vacancies found");
        } else {
          this.vacanciesLoaded = true;
        }
      } else {
        this.toggleModal(this.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    searchForPage(pageNumber) {
      this.searchModel.page = pageNumber;
      // console.log("CURRENT PAGE => ", this.searchModel.page);
      this.search();
      document.getElementById("vacancy").scrollIntoView();
    },
    find() {
      this.searchModel.page = 1;
      // console.log("CURRENT PAGE => ", this.searchModel.page);
      this.search();
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
          toast_modal.classList.remove('open');
          overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  computed: {
    featuredVacancies() {
      return this.vacancies.items.filter(x => x.isFutured);
    }
  },
  data() {
    return {
      styleMult: "bottom",
      states: [],
      vacanciesLoaded: false,
      isLoading: false,
      vacancies: {
        items: [],
        totalCount: 0,
        pageCount: 0
      },
      driverTypes: [],
      freightTypes: [],
      routeTypes: [],
      endorsements: [],
      datePostedValues: [
        {
          id: "LastHour",
          name: 'Last hour'
        },
        {
          id: "LastSixHours",
          name: 'Last 6 hours'
        },
        {
          id: "LastDay",
          name: 'Last day'
        },
        {
          id: "LastWeek",
          name: 'Last week'
        },
        {
          id: "LastMonth",
          name: 'Last month'
        },
        {
          id: "AllTime",
          name: 'All the time'
        }
      ],
      experiences: [],
      searchModel: {
        companyName: '',
        stateName: '',
        experienceId: 0,
        driverTypeIdList: [],
        freightTypeIdList: [],
        routeTypeIdList: [],
        endorsementIdList: [],
        timeRange: 'AllTime',
        page: 1,
        size: 6
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  }
}
</script>
