<template>
  <section class="plans_content">

    <section class="title">
      <div class="container">
        <h2>Choose Your plan</h2>
      </div>
      <div class="container">
        <h4>We offer a variety of plans to meet your hiring needs</h4>
      </div>
    </section>

    <div class="container">
      <CompanyChoosePlanComponent />
    </div>
  </section>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import CompanyChoosePlanComponent from '@/components/companyChoosingPlanComponents/companyChoosePlanComponent.vue';




export default {
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    }
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: { CompanyChoosePlanComponent, Loading }
}
</script>

<style >
.plans_content .talk_us {
  margin-top: 41px;
  margin-bottom: 100px;
}
</style>