var handleRequestError = async function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        window.localStorage.clear();
        location.reload();
      }
      else if (error.response.status === 404){
        return {
          data: {
            successful: false,
            errors: [
              {
                messages: ["Запрашиваемый ресурс не найден"],
                key: "notFound",
              },
            ],
          },
        };  
      }
      return {
        data: {
          successful: false,
          errors: [
            {
              messages: [error],
              key: "notExpectedError",
            },
          ],
        },
      };
    } else {
      return {
        data: {
          successful: false,
          errors: [
            {
              messages: [
                "Нет связи с сервером... Попробуйте чуть позже.",
              ],
              key: "noConnectionToAPI",
            },
          ],
        },
      };
    }
  };
  
  export default {
    handleRequestError,
  };
  