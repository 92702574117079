import { createStore } from 'vuex'

const store = createStore({
  state: {
    carrierAccessToken: null
  },
  getters: {
    carrierAccessToken: (state) => state.carrierAccessToken,
  },
  mutations: {
    initializeState(state) {
      if(localStorage.getItem('state')) {
        this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('state')))
				);
        console.log("STATE INITIALIZED FROM LOCAL STORAGE WITH METHOD => REPLACE STATE");
      }
    },
    authenticateUser(state, { carrierAccessToken }) {
      state.carrierAccessToken = carrierAccessToken;
      // console.log("CARRIER IS AUTHENTICATED => ", state.carrierAccessToken);

      localStorage.setItem('state', JSON.stringify(state));
      // console.log("CARRIER ACCESS TOKEN SAVED TO LOCAL STORAGE");
    },
    logOutCarrier(state) {
      state.carrierAccessToken = null;

      localStorage.setItem('state', JSON.stringify(state));
    }
  }
})

export default store;
