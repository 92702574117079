<template>
    <section>
    </section>
    <TitleBlock></TitleBlock>
    <DriverApplication2 :registeredDriverId="registeredDriverId" :vacancyId="vacancyId"></DriverApplication2>
</template>

<script>
    import TitleBlock from '@/components/driverApplicationComponents/titleBlockComponent.vue'
    import DriverApplication2Component from '@/components/driverApplicationComponents/driverApplicationMainSecondComponent.vue'

    export default {
        name: 'DriverApplication2View',
        components: {
            TitleBlock: TitleBlock,
            DriverApplication2: DriverApplication2Component
        },
        props: ["vacancyId", "registeredDriverId"]
          
    }
</script>
