<template>
  <section class="home_what_offers">
    <div class="container">
      <div class="top_block">
        <div class="col_left">
          <p class="green_txt">
            Recruiting made simple
          </p>
          <h2>
            What We Offer
          </h2>
        </div>
        <div class="col_right">
          Finding experienced drivers is hard work, and for many trucking companies, it’s becoming increasingly difficult. Fortunately, your virtual recruiter can step in and make the entire hiring process significantly easier for businesses of all sizes.
        </div>
      </div>
      <div class="adv_items">
        <adv-item-component v-for="card in cardData" :key=card.id :imgSrc=card.imageSrc :headerText=card.header
          :innerText=card.text />
      </div>
    </div>
  </section>
</template>

<script>
import AdvItemComponent from './inComponent/advItemComponent.vue';

export default {
  data() {
    return {
      cardData: [{
        id: 1,
        imageSrc: "hiring.svg",
        header: "Hiring All in One",
        text: "Our innovative platform that is designed for companies allows making the recruiting process - faster, more efficient, and less time-consuming. Imagine if you could input your hiring criteria, create featured ads and get access to daily oncoming direct leads online. That’s your reality with findCDL."
      }, {
        id: 2,
        imageSrc: "onlineSevise.svg",
        header: "Online Service",
        text: "Driver candidates can submit a simple application with their basic info and experience and let the trucking companies connect with them.  Partner carriers will have access to all oncoming leads through their online account and make the hiring procedure flow more smoothly without stress for the company's HR team. "
      }, {
        id: 3,
        imageSrc: "connection.svg",
        header: "Connection",
        text: "This platform acts as the bridge between drivers who are looking for a career in the trucking industry and employers who are seeking qualified candidates. Our marketing strategy emphasizes solely on drivers who are actively searching for their prospective employers."
      }
      ]
    };
  },
  components: { AdvItemComponent }
}
</script>

<style >

</style>