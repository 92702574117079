

export function validatePhoneNumber(phoneNumber) {
    const firstPhoneNumberRegex = /\+\d\d\d\d\d\d\d\d\d\d\d/i;
    const secondPhoneNumberRegex = /\+\d-\d\d\d-\d\d\d-\d\d\d\d/i;
    const thirdPhoneNumberRegex = /\d-\d\d\d-\d\d\d-\d\d\d\d/i;
    const fourthPhoneNumberRegex = /\d\d\d\d\d\d\d\d\d\d\d/;

    return phoneNumber.match(firstPhoneNumberRegex) 
        || phoneNumber.match(secondPhoneNumberRegex) 
        || phoneNumber.match(thirdPhoneNumberRegex) 
        || phoneNumber.match(fourthPhoneNumberRegex);
}

export function validateDate(date) {
    return isNaN(Date.parse(date)) == false;
}

export function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function validateZipCode(zipCode) {
    let regex = /\b\d{5}\b/g;
    return zipCode.match(regex);
}

export function validatePassword(password) {
    let regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
    return regex.test(password)
}