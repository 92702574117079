<template>
  <section class="driver_app_main">
    <div class="container">
      <div class="row">
        <div class="form_block">
          <div class="row_form">
            <!-- FIRST NAME -->
            <div class="col_form">
              <label class="form-label" :class="{ 'error_class': v$.driverData.firstName.$error }">
                First Name
                <span>*</span>
              </label>
              <input class="form-control" :class="{ 'error_border': v$.driverData.firstName.$error }" type="text"
                v-model="driverData.firstName" @blur="v$.driverData.firstName.$touch" placeholder="First Name">
              <div class="error_class" v-if="v$.driverData.firstName.$error">
                Invalid first name
              </div>
            </div>

            <!-- LAST NAME -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.driverData.lastName.$error }">
                Last Name
                <span>*</span>
              </label>
              <input type="text" :class="{ error_border: v$.driverData.lastName.$error }"
                @blur="v$.driverData.lastName.$touch" class="form-control" placeholder="Last Name"
                v-model="driverData.lastName">
              <div class="error_class" v-if="v$.driverData.lastName.$error">
                Invalid last name
              </div>
            </div>
          </div>

          <div class="row_form">
            <!-- EMAIL -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.driverData.user.email.$error }">
                Email
                <span>*</span>
              </label>
              <input type="text" class="form-control" :class="{ error_border: v$.driverData.user.email.$error }"
                placeholder="Your email" v-model="driverData.user.email" @blur="v$.driverData.user.email.$touch">
              <div class="error_class" v-if="v$.driverData.user.email.$error">
                Invalid email
              </div>
            </div>

            <!-- PHONE NUMBER -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.driverData.user.phoneNumber.$error }">
                Phone number
                <span>*</span>
              </label>
              <input type="text" class="form-control" :class="{ error_border: v$.driverData.user.phoneNumber.$error }"
                placeholder="+1 (206) xxx-xxxx" v-model="driverData.user.phoneNumber"
                v-maska="['+1 (###) ###-####', '+1 (###) ###-####']" @blur="v$.driverData.user.phoneNumber.$touch">
              <div class="error_class" v-if="v$.driverData.user.phoneNumber.$error">
                Invalid phone number
              </div>
            </div>

          </div>
          <div class="row_form top_block">
            <!-- DATE OF BIRTH -->
            <div class="col_form col_half">
              <label class="form-label" :class="{ error_class: v$.driverData.birthDate.$error }">
                Date of birth
                <span>*</span>
              </label>
              <input type="date" class="form-control" :class="{
                error_border:
                  v$.driverData.birthDate.$error
              }" placeholder="dd/mm/yyyy" v-model="driverData.birthDate" @blur="v$.driverData.birthDate.$touch"
                min="1940-01-01">
              <div class="error_class" v-if="v$.driverData.birthDate.$error">
                Invalid date
              </div>
            </div>
          </div>
          <div class="row_form">
            <!-- TARGET ADDRESS -->
            <div class="col_form">
              <label class="form-label">
                Address
              </label>
              <input type="text" class="form-control" placeholder="Your address"
                v-model="driverData.address.targetAddress" maxlength="100">
            </div>

            <!-- CITIES -->
            <div class="col_form">
              <label class="form-label">
                City
              </label>
              <input v-model="driverData.address.city" type="text" class="form-control" placeholder="City">
            </div>
          </div>
          <div class="row_form">

            <!-- STATES -->
            <div class="col_form state_form">

              <label class="form-label" :class="{ error_class: v$.driverData.address.stateId.$error }">
                States
                <span>*</span>
              </label>

              <Multiselect :classes="{
                container: 'multiselect_container',
                options: 'multiselect-options multiselect_options',
                search: 'multiselect_search'
              }" mode="single" :options="states" :openDirection="styleMult" valueProp="id" label="name"
                v-model="driverData.address.stateId" :hide-selected="false" :close-on-select="true"
                noOptionsText="No state found!" noResultsText="No state found!" :multiple="false" :taggable="false"
                placeholder="Washington, Seattle...." />


              <div class="error_class" v-if="v$.driverData.address.stateId.$error">
                Invalid State
              </div>


            </div>


            <!-- ZIP CODE -->
            <div class="col_form">
              <label class="form-label" :class="{ error_class: v$.driverData.address.zipCode.$error }">
                Zip code
                <span>*</span>
              </label>

              <input type="text" class="form-control" :class="{ error_border: v$.driverData.address.zipCode.$error }"
                placeholder="00000" v-model="driverData.address.zipCode" @blur="v$.driverData.address.zipCode.$touch"
                maxlength="5">
              <div class="error_class" v-if="v$.driverData.address.zipCode.$error">
                Invalid zip code
              </div>
            </div>

          </div>
          <div class="bot_row">
            <div class="form-check">

              <input class="form-styled checkbox_styled" type="checkbox" value="" id="check_1"
                v-model="isConsentButtonPressed">
              <label for="check_1">
                By submitting my application, I expressly consent to be contacted by freight carrier(s) and/or find CDL regarding 
                potential employment opportunities via email, telephone or SMS. I understand that the calls and messages may be 
                transmitted using an auto dialing system or prerecorded voice.
              </label>
            </div>
            <button data-toggle="modals" data-target="#modals-license" class="button"
              :disabled="!isConsentButtonPressed || this.v$.$invalid"
              :class="{ 'but_blue': isConsentButtonPressed && !this.v$.$invalid }"> Next
            </button>
          </div>
        </div>

        <div class="driver_app_vacancy" v-if="!isMobile()">

          <div class="info_items">
            <div class="hiring_now_driver_app_items"> 
              <h3>Hiring now</h3>
              <div class="label_top items_label_top"><p>Featured</p></div>
              <Vacancy v-for="vacancy in sliceVacancies" :key=vacancy.id :vacancy="vacancy" />
            </div>

            <div></div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <loading :active="isLoading" :can-cancel="true" />
  <div class="overlay hidden"></div>

  <!-- License modal -->
  <div class="modals modal_license" id="modals-license">
    <div class="modal_more_info_wrap">
      <div class="modal_top">
        <img src="@/assets/images/docs.svg" alt="License">
        <a href="#" class="close btn-close" data-close="modals">⨉</a>
      </div>
      <div class="modal_content">
        <p class="modals_title">Do you have a valid Class A CDL License?</p>
      </div>
      <div class="modal_foot">
        <button @click="redirectToCdlLicensePage" target="_blank" class="button but_bordered close"
          data-close="modals">No</button>
        <button @click="registerDriver" class="button but_green" data-close="modals">Yes</button>
      </div>
    </div>
  </div>

  <!-- Modal notification -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="#" class="close btn-close" data-close="modals">⨉</a>
  </div>

</template>

<script>
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import StateService from '@/services/stateService.js';
import AuthService from '@/services/authenticationService';
import VacancyService from '@/services/vacancyService.js';
import { validateEmail, validateDate, validateZipCode } from '@/services/validationService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VacancyComponent from '../homeComponents/inComponent/vacancyComponent.vue';

export default {
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    addEventListener("resize", () => {
      var w = window.innerWidth;
      if (w < 799) {
        this.styleMult = "top"
      } else {
        this.styleMult = "bottom"
      }
    });
    this.loadVacancy();
    this.getVacancies();
    this.loadStates();
    document.addEventListener('click', function (e) {
      const overlay_modals = document.querySelector(".overlay");
      let $target = e.target;
      if ($target.closest('[data-toggle="modals"]')) {
        e.preventDefault();
        $target = $target.closest('[data-toggle="modals"]');
        document.querySelector($target.dataset.target).classList.add('open');
        overlay_modals.setAttribute("data-closeoverlay", $target.dataset.target);
        overlay_modals.classList.remove("hidden");
      } else if ($target.dataset.close === 'modals') {
        e.preventDefault();
        $target.closest('.modals').classList.remove('open');
        overlay_modals.classList.add("hidden");
      }
      else if ($target.dataset.closeoverlay != null) {
        e.preventDefault();
        document.querySelector($target.dataset.closeoverlay).classList.remove('open');
        overlay_modals.classList.add("hidden");
        $target.dataset.closeoverlay = null;
      }
    });
  },
  components: {
    Loading,
    Multiselect,
    Vacancy: VacancyComponent
  },

  methods: {
    isMobile() {
      return window.innerWidth <= 480;
    },
    loadVacancy: async function () {
      var self = this;
      this.isLoading = true;
      var response = await VacancyService.get(self.searchModel);
      this.isLoading = false;
      this.vacancies = response.data.items;
    },
    loadStates: async function () {
      this.isLoading = true;
      var response = await StateService.getStates();
      this.states = response.data.items;
      this.isLoading = false;
    },
    registerDriver: async function () {
      var self = this;
      self.v$.$validate();
      if (!self.v$.$error && self.isConsentButtonPressed) {
        this.isLoading = true;
        var response = await AuthService.registerDriver(self.driverData);
        this.isLoading = false;
        if (response.data.successful) {
          this.registeredDriverId = response.data.item;
          this.$router.push({
            path: `/driver-application2/${this.registeredDriverId}/${this.vacancyId}`
          })
        } else {
          self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0])
        }
      } else {
        self.toggleModal(self.modal.warningIcon, "Warning", "Fill out the form properly");
      }
    },
    redirectToCdlLicensePage() {
      window.open('https://www.fmcsa.dot.gov/registration/commercial-drivers-license/how-do-i-get-commercial-drivers-license', '_blank');
    },
    getVacancies: async function () {
      var self = this;
      var response = await VacancyService.get(self.vacancySearchModel);

      if (response.data.successful) {
        // console.log("=> HOT VACANCIES LOADED SUCCESSFULLY");

        this.hotVacancies = response.data.items;
      } else {
        // console.log("=> COULD NOT LOAD HOT VACANCIES");
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
          toast_modal.classList.remove('open');
          overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },

  computed: {
    sliceVacancies() {
      return this.vacancies.filter(x => x.isFutured).slice(0, 3);
    }
  },
  data() {
    return {
      styleMult: "bottom",
      vacancies: [],
      searchModel: {
        companyName: '',
        stateName: '',
        experienceId: 0,
        driverTypeIdList: [],
        freightTypeIdList: [],
        routeTypeIdList: [],
        endorsementIdList: [],
        timeRange: 'AllTime',
        page: 1,
        size: 6
      },
      isLoading: false,
      driverData: {
        firstName: '',
        lastName: '',
        birthDate: '2000-01-01',
        user: {
          email: '',
          phoneNumber: ''
        },
        address: {
          address: '',
          zipCode: '',
          city: '',
          stateId: null
        }
      },
      vacancySearchModel: {
        companyName: '',
        stateName: '',
        experienceId: 0,
        driverTypeIdList: [],
        freightTypeIdList: [],
        routeTypeIdList: [],
        endorsementIdList: [],
        timeRange: 'AllTime',
        page: 1,
        size: 6
      },
      cities: [],
      states: [],
      hotVacancies: [],
      isConsentButtonPressed: false,
      registeredDriverId: null,
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  props:["vacancyId"],
  validations() {
    return {
      driverData: {
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),

        },
        birthDate: {
          required,
          validateDate
        },
        user: {
          email: {
            required,
            validateEmail
          },
          phoneNumber: {
            required,
            minLength: minLength(16),
            maxLength: maxLength(19),
          }
        },
        address: {
          zipCode: {
            required,

            validateZipCode
          },
          stateId: {
            required
          }
        }
      }
    }
  }
}
</script>
