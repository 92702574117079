/* eslint-disable */
import axios from "axios";
import errorHandler from "@/helpers/errorHandler";

export default {
  loginUser: async function(credentials) {
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    var response = {};

    await instance
      .post("api/auth/login", credentials)
      .then((res) => {
        response = res;
      })
      .catch(async error => {
        response = await errorHandler.handleRequestError(error);
      });
    return response;
  },
  registerDriver: async function(driverData) {
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        'Content-Type': 'application/json',
      }
    });

    var response = {};
    await instance
      .post("api/driver/createDriver", driverData)
      .then(res => {
       response = res;
      })
      .catch(async error => {
        console.log(error);
        response = await errorHandler.handleRequestError(error);
      });

      return response;
  }
};
