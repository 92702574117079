<template>
  <section class="about_us">
    <div class="container">
      <div class="inner_block">
        <div>
          <div class="green_txt">
              Why us?
          </div>
          <h2>
              Company Insights
          </h2>
          <div class="about_us_items">
            <div class="about_us_item">
              <span>
              Online Company Profile and Statistics Dashboard
              </span>
            </div>
            <div class="about_us_item">
              <span>
              Customized and Editable Hiring Requirements                
              </span>
            </div>
            <div class="about_us_item">
              <span>
              Create Featured Ads and Maximize Your Reach and Visibility                
              </span>
            </div>
            <div class="about_us_item">
              <span>
              Access to Daily Oncoming Direct Leads and Email Alerts                
              </span>
            </div>
            <div class="about_us_item">
              <span>
              Excellent Customer Support and Guidance                
              </span>
            </div>
          </div>
          <p>
            As this platform serves as liaison between qualified candidates and employers, our marketing strategy emphasizes on a real-time talent market data. Applicants will be provided with multiple options by submitting simple and most importantly, free application form.
          </p>
          <p>
            This innovative recruitment tool offers various advantages for the employers in order to make the hiring process more efficient and less expensive.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>